





































































import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import UserDto from '@/app/user/dto/user.dto';
import Avatar from '@/app/core/components/avatar.vue';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import ConfirmationDialog from '@/app/core/components/confirmation-dialog.vue';
import apartmentsService from '../services/apartments.service';
import ApartmentAddOwnerDialog from './apartment-add-owner-dialog.vue';
import ApartmentEditOwnerContractDialog from './apartment-edit-contract-dialog.vue';

@Component({
  components: {
    Avatar,
  },
})
export default class ApartmentReservations extends HandlesErrorMixin {
  @Prop({ type: Boolean, required: true }) loading!: boolean;

  @Prop({ type: String, required: true }) apartmentId!: string;

  @Prop({ type: String, required: true }) apartmentName!: string;

  owners: UserDto[] = [];

  @Emit('setLoading')
  setLoading(loading: boolean) {
    return loading;
  }

  @Watch('apartmentId', { immediate: true })
  async getApartmentOwners() {
    this.setLoading(true);

    try {
      const res = await apartmentsService.getApartmentOwners(this.apartmentId);
      this.owners = res.data.items;
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setLoading(false);
    }
  }

  async onEditOwnerContract(owner: UserDto) {
    await this.$dialog.open(ApartmentEditOwnerContractDialog, {
      apartmentId: this.apartmentId,
      ownerId: owner.id,
    });
  }

  async onRemoveOwner(owner: UserDto) {
    const title = this.$t('title.removeOwner');
    const message = this.$t('message.confirmRemoveOwner', {
      apartment: this.apartmentName,
      owner: `${owner.profile.firstname} ${owner.profile.lastname}`,
    });
    const confirm = await this.$dialog.open(ConfirmationDialog, { title, message });

    if (!confirm) return;

    this.setLoading(true);
    try {
      await apartmentsService.removeApartmentOwner(this.apartmentId, owner.id);
      this.$notify.success(this.$t('success.apartmentOwnerRemoved').toString());
      this.getApartmentOwners();
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setLoading(false);
    }
  }

  async onAddOwner() {
    const owner = await this.$dialog.open<UserDto>(ApartmentAddOwnerDialog, {
      apartmentId: this.apartmentId,
    });

    if (!owner) return;

    this.setLoading(true);
    try {
      await apartmentsService.addApartmentOwner(this.apartmentId, owner.id);
      this.$notify.success(this.$t('success.apartmentOwnerAdded').toString());
      this.getApartmentOwners();
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setLoading(false);
    }
  }
}
