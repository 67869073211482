

















































































































import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import FormMixin from '@/app/core/mixins/form.mixin';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import { ApartmentAutoMessagesDto } from '../dto/apartment.dto';
import { getApartmentAutoMessages, updateApartmentAutoMessages } from '../services/apartments.service';

@Component
export default class ApartmentAutoMessages extends Mixins(HandlesErrorMixin, FormMixin) {
  @Prop({ type: String, required: true }) apartmentId!: string;

  data: ApartmentAutoMessagesDto = {
    welcomeMessage: {
      en: '',
      es: '',
    },
    checkInReminder: {
      en: '',
      es: '',
    },
    checkOutReminder: {
      en: '',
      es: '',
    },
  };

  @Emit('setLoading')
  setLoading(loading: boolean) {
    return loading;
  }

  @Watch('apartmentId', { immediate: true })
  async getApartmentAutoMessages() {
    this.setLoading(true);

    try {
      const { data } = await getApartmentAutoMessages(this.apartmentId);
      this.data = { ...this.data, ...data };
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setLoading(false);
    }
  }

  async updateApartmentAutoMessages() {
    this.setLoading(true);

    try {
      await updateApartmentAutoMessages(this.apartmentId, this.data);
      this.$notify.success(this.$t('success.changesSaved').toString());
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setLoading(false);
    }
  }
}
