



































































































import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { gte, lte, required } from '@/app/core/validation';
import FormMixin from '@/app/core/mixins/form.mixin';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import ContractDto from '../dto/contract.dto';
import ReservationRangePicker from './reservation-range-picker.vue';

@Component({
  components: {
    ReservationRangePicker,
  },
})
export default class EditContractForm extends Mixins(FormMixin, HandlesErrorMixin) {
  @Prop({ type: Object, required: true })
  private contract!: ContractDto;

  protected data: ContractDto = {
    percent: 0,
    canReserve: false,
    unlimitedReservations: false,
    reservationRanges: [],
  };

  protected rules = {
    percent: [required, gte(0), lte(100)],
  };

  get reservationRangesDisabled() {
    return !this.data.canReserve || this.data.unlimitedReservations;
  }

  @Watch('contract', { immediate: true })
  setData() {
    this.data = this.contract;
  }

  @Emit('cancel')
  onCancel() {
    return false;
  }

  addRange() {
    const now = (new Date()).toISOString();
    this.data.reservationRanges.push({
      from: now,
      to: now,
      days: 0,
    });
  }

  removeRange(index: number) {
    this.data.reservationRanges.splice(index, 1);
  }
}
