


























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ValidationRules } from '@/app/core/types';
import { gte, required } from '@/app/core/validation';
import DatePicker from '@/app/core/components/date-picker.vue';
import ConfirmationDialog from '@/app/core/components/confirmation-dialog.vue';
import { ReservationRangeDefinitionDto } from '../dto/contract.dto';

@Component({
  components: {
    DatePicker,
  },
})
export default class ReservationRangePicker extends Vue {
  @Prop({ type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ type: Object })
  range!: ReservationRangeDefinitionDto;

  rules: ValidationRules = {
    from: [required],
    to: [required],
    days: [required, gte(0)],
  };

  @Watch('range.from', { immediate: true })
  adjustDeparture(value: string) {
    if (this.range.from > this.range.to) {
      this.range.to = value;
    }
  }

  allowedToDates(date: string) {
    return date >= this.range.from;
  }

  async onRemove() {
    const title = this.$t('title.removeRange');
    const message = this.$t('message.confirmRemoveRange');
    const confirm = await this.$dialog.open(ConfirmationDialog, { title, message });

    if (confirm) this.$emit('remove');
  }
}
