





































































































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import env from '@/env';
import FormMixin from '@/app/core/mixins/form.mixin';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import { ApartmentICalSyncDto } from '../dto/apartment.dto';
import {
  getApartmentICalSyncConfig,
  updateApartmentICalSyncConfig,
  apartmentICalSync,
} from '../services/ics.service';
import SyncICalReport from '../dto/sync-ical-report';

@Component
export default class ApartmentICal extends Mixins(FormMixin, HandlesErrorMixin) {
  @Prop({ type: String, required: true }) apartmentId!: string;

  private syncing = false;
  showIntervalModal = false;
  private showICalSyncReport = false;
  private iCalSyncReport: SyncICalReport = {
    created: 0,
    updated: 0,
    ignored: 0,
    cancelled: 0,
  };

  protected data: ApartmentICalSyncDto = {
    url: '',
    active: false,
    interval: 60 * 60 * 1000, // every 60 minutes
  };

  get iCalUrl() {
    return `${env.API_BASE_URL}/ics/apartment/${this.apartmentId}`;
  }

  copyICalUrlToClipboard() {
    navigator.clipboard.writeText(this.iCalUrl);
    this.$notify.success(this.$t('success.iCalURLCopied').toString());
  }

  setICalSyncReportVisible() {
    this.showICalSyncReport = true;

    setTimeout(() => {
      this.showICalSyncReport = false;
    }, 10000);
  }

  async sync() {
    this.syncing = true;

    try {
      const res = await apartmentICalSync(this.apartmentId);
      this.iCalSyncReport = res.data;
      this.setICalSyncReportVisible();
    } catch (error) {
      this.handleError(error);
    } finally {
      this.syncing = false;
    }
  }

  @Watch('apartmentId', { immediate: true })
  async loadData() {
    this.internalLoading = true;

    try {
      const res = await getApartmentICalSyncConfig(this.apartmentId);
      this.data = { ...this.data, ...res.data };
    } catch (error) {
      this.handleError(error);
    } finally {
      this.internalLoading = false;
    }
  }

  async afterSubmit() {
    this.internalLoading = true;

    try {
      await updateApartmentICalSyncConfig(this.apartmentId, this.data);
      this.$notify.success(this.$t('success.iCalSyncConfigUpdated').toString());
    } catch (error) {
      this.handleError(error);
    } finally {
      this.internalLoading = false;
    }
  }
}
