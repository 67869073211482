import api from '@/app/core/services/api.service';
import SyncICalReport from '../dto/sync-ical-report';
import { ApartmentICalSyncDto } from '../dto/apartment.dto';

export const getApartmentICalSyncConfig = (apartmentId: string) => (
  api.get<ApartmentICalSyncDto>(`/ics/apartment/${apartmentId}/sync-config`)
);

export const updateApartmentICalSyncConfig = (apartmentId: string, dto: ApartmentICalSyncDto) => (
  api.put(`/ics/apartment/${apartmentId}/sync-config`, dto)
);

export const apartmentICalSync = (apartmentId: string) => (
  api.put<SyncICalReport>(`/ics/apartment/${apartmentId}/sync`)
);
