var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",staticClass:"pa-4 pt-5",attrs:{"disabled":_vm.isLoading},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-expand-transition',[(_vm.showICalSyncReport)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-alert',{attrs:{"icon":"mdi-calendar-plus","border":"right","colored-border":"","type":"success","elevation":"2"}},[_vm._v(" New reservations: "+_vm._s(_vm.iCalSyncReport.created)+" ")])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-alert',{attrs:{"icon":"mdi-calendar-edit","border":"right","colored-border":"","type":"warning","elevation":"2"}},[_vm._v(" Updated reservations: "+_vm._s(_vm.iCalSyncReport.updated)+" ")])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-alert',{attrs:{"icon":"mdi-calendar","border":"right","colored-border":"","type":"info","elevation":"2"}},[_vm._v(" Unmodified reservations: "+_vm._s(_vm.iCalSyncReport.ignored)+" ")])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-alert',{attrs:{"icon":"mdi-calendar-remove-outline","border":"right","colored-border":"","type":"error","elevation":"2"}},[_vm._v(" Canceled reservations: "+_vm._s(_vm.iCalSyncReport.cancelled)+" ")])],1)],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"readonly":"","outlined":"","persistent-hint":"","color":"secondary","value":_vm.iCalUrl,"hint":_vm.$t('hint.iCalUrl'),"label":_vm.$t('label.iCalUrl')}},[_c('v-tooltip',{attrs:{"slot":"append","top":""},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-top":"-7px"},attrs:{"icon":"","color":"primary"},on:{"click":_vm.copyICalUrlToClipboard}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('tooltip.copy'))+" ")])])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"outlined":"","persistent-hint":"","color":"secondary","label":_vm.$t('label.iCalSyncUrl'),"hint":_vm.$t('hint.iCalSyncUrl'),"prepend-inner-icon":"mdi-calendar-sync"},model:{value:(_vm.data.url),callback:function ($$v) {_vm.$set(_vm.data, "url", $$v)},expression:"data.url"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"outlined":"","hide-details":"","color":"secondary","disabled":!_vm.data.active,"label":"Auto-sync interval","prepend-inner-icon":"mdi-clock-outline","items":[
           {
             text: '5 minutes',
             value: 5 * 60 * 1000,
           },
           {
             text: '10 minutes',
             value: 10 * 60 * 1000,
           },
           {
             text: '30 minutes',
             value: 30 * 60 * 1000,
           },
           {
             text: '45 minutes',
             value: 45 * 60 * 1000,
           },
           {
             text: '60 minutes',
             value: 60 * 60 * 1000,
           } ]},model:{value:(_vm.data.interval),callback:function ($$v) {_vm.$set(_vm.data, "interval", $$v)},expression:"data.interval"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-switch',{attrs:{"dense":"","color":"secondary","label":_vm.$t('label.iCalSyncActive')},model:{value:(_vm.data.active),callback:function ($$v) {_vm.$set(_vm.data, "active", $$v)},expression:"data.active"}})],1)],1),_c('div',{staticClass:"d-flex justify-center mt-4"},[_c('v-btn',{staticClass:"mr-3",attrs:{"large":"","outlined":"","color":"secondary","disabled":!_vm.data.url || _vm.isLoading || _vm.syncing},on:{"click":_vm.sync}},[_c('v-icon',{staticClass:"pr-1",class:{ 'mdi-spin': _vm.syncing },attrs:{"size":"20"}},[_vm._v(" mdi-sync ")]),_vm._v(" "+_vm._s(_vm.$t('btn.sync'))+" ")],1),_c('v-btn',{attrs:{"large":"","type":"submit","color":"secondary","loading":_vm.isLoading,"disabled":!_vm.formValid || _vm.isLoading || _vm.syncing}},[_c('v-icon',{staticClass:"pr-1",attrs:{"size":"20"}},[_vm._v("mdi-pencil")]),_vm._v(" "+_vm._s(_vm.$t('btn.update'))+" ")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }