





















































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import ApartmentDto from '../dto/apartment.dto';

@Component
export default class ApartmentInfo extends Vue {
  @Prop({ type: Object, required: true })
  private apartment!: ApartmentDto;
}
