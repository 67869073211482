
































import sample from 'lodash/sample';
import { Getter } from 'vuex-class';
import { CalendarEvent, CalendarEventParsed } from 'vuetify';
import AuthModule from '@/app/auth/store';
import { Component, Prop, Ref } from 'vue-property-decorator';
import ReservationDto from '@/app/reservations/dto/reservation.dto';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import { getReservations } from '@/app/reservations/services/reservations.service';

interface ClickEvent {
  event: CalendarEvent;
  eventParsed: CalendarEventParsed;
}

interface Calendar {
  lastStart: string;
  lastEnd: string;
}

@Component
export default class ApartmentReservations extends HandlesErrorMixin {
  @Ref('calendar') calendarRef!: Calendar;

  @Prop({ type: String, required: true }) apartmentId!: string;

  @Getter
  private isHost!: typeof AuthModule.prototype.isHost;

  private loading = false;
  private reservations: ReservationDto[] = [];
  private value = '';
  private colors = [
    'red',
    'pink',
    'purple',
    'deep-purple',
    'indigo',
    'blue',
    'light-blue',
    'cyan',
    'teal',
    'green',
    'light-green',
    'amber',
    'orange',
    'deep-orange',
    'brown',
    'blue-grey',
    'grey',
  ];

  get events() {
    return this.reservations.map(reservation => {
      const sevenHours = 7 * 3600000;
      const arrival = Date.parse(reservation.arrival) + sevenHours;
      const departure = Date.parse(reservation.departure) + sevenHours;

      return {
        id: reservation.id,
        name: this.getEventName(reservation),
        start: new Date(arrival),
        end: new Date(departure),
        color: sample(this.colors),
      };
    });
  }

  getEventName(reservation: ReservationDto) {
    if (reservation.channel?.name === 'Blocked channel') {
      return this.$t('label.blocking');
    }

    return `${reservation.guest?.firstname} ${reservation.guest?.lastname}`;
  }

  getEventColor(event: CalendarEvent) {
    return event.color;
  }

  onEventClick({ event }: ClickEvent) {
    if (this.isHost) {
      this.$router.push(`/reservations/details/${event.id}`);
    }
  }

  async getReservations({ start, end }: CalendarEventParsed) {
    this.loading = true;

    try {
      const isoStart = new Date(`${start.date}T00:00:00`).toISOString();
      const isoEnd = new Date(`${end.date}T23:59:59`).toISOString();

      const response = await getReservations({
        apartmentId: this.apartmentId,
        from: isoStart,
        to: isoEnd,
      });

      this.reservations = response.data.reservations;
    } catch (error) {
      this.handleError(error);
    } finally {
      this.loading = false;
    }
  }
}
