


















































import { Getter } from 'vuex-class';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import AuthModule from '@/app/auth/store';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import ApartmentPicture from './apartment-picture.vue';
import { getApartmentPictures, uploadApartmentPictures } from '../services/apartments.service';

@Component({
  components: {
    ApartmentPicture,
  },
})
export default class ApartmentPictures extends HandlesErrorMixin {
  @Getter
  private isHost!: typeof AuthModule.prototype.isHost;

  @Prop({ type: String, required: true })
  private apartmentId!: string;

  @Ref('fileInput') fileInput!: HTMLInputElement;

  private loading = false;
  private pictures: string[] = [];

  @Watch('apartmentId', { immediate: true })
  async loadPictures() {
    this.loading = true;

    try {
      const response = await getApartmentPictures(this.apartmentId);
      this.pictures = response.data;
    } catch (error) {
      this.handleError(error);
    } finally {
      this.loading = false;
    }
  }

  onPictureRemoved(picture: string) {
    this.pictures = this.pictures.filter((p) => p !== picture);
  }

  onFileInputChange(event: Event) {
    const target = event.target as HTMLInputElement;

    if (!target.files || target.files.length === 0) return;

    const pictures: File[] = [];
    for (let i = 0; i < target.files.length; i += 1) {
      const picture = target.files.item(i);
      if (picture != null) {
        pictures.push(picture);
      }
    }
    this.uploadPictures(pictures);
  }

  async uploadPictures(pictures: File[]) {
    this.loading = true;

    try {
      const response = await uploadApartmentPictures(this.apartmentId, pictures);
      const uploadedPictures = response.data;

      this.pictures = [...uploadedPictures, ...this.pictures];
    } catch (error) {
      this.handleError(error);
    } finally {
      this.loading = false;
    }
  }
}
