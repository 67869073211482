
































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import { downloadApartmentPicture, removeApartmentPicture } from '../services/apartments.service';

@Component
export default class ApartmentPicture extends Mixins(HandlesErrorMixin) {
  @Prop({ type: String, required: true })
  apartmentId!: string;

  @Prop({ type: String, required: true })
  picture!: string;

  private loading = false;

  private src: string | ArrayBuffer | null = null;

  @Watch('picture', { immediate: true })
  async loadPicture() {
    this.loading = true;

    try {
      const picture = await this.downloadPicture();
      this.displayPicture(picture);
    } catch (error) {
      this.handleError(error);
    } finally {
      this.loading = false;
    }
  }

  async removePicture() {
    this.loading = true;

    try {
      await removeApartmentPicture(this.apartmentId, this.picture);
      this.$emit('pictureRemoved', this.picture);
    } catch (error) {
      this.handleError(error);
    } finally {
      this.loading = false;
    }
  }

  async downloadPicture() {
    return (await downloadApartmentPicture(this.apartmentId, this.picture)).data;
  }

  displayPicture(picture: Blob) {
    const reader = new FileReader();

    reader.readAsDataURL(picture);
    reader.onloadend = () => {
      this.src = reader.result;
    };
  }
}
