




















import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import { Component, Emit, Prop } from 'vue-property-decorator';
import ContractDto from '../dto/contract.dto';
import EditContractForm from './edit-contract-form.vue';
import apartmentsService from '../services/apartments.service';

@Component({
  components: {
    EditContractForm,
  },
})
export default class AparmentEditContractDialog extends HandlesErrorMixin {
  loading = false;

  private contract: ContractDto = {
    percent: 0,
    canReserve: false,
    unlimitedReservations: false,
    reservationRanges: [],
  };

  @Prop({ type: String, required: true })
  readonly apartmentId!: string;

  @Prop({ type: String, required: true })
  readonly ownerId!: string;

  @Emit('close')
  close(data: ContractDto | false) {
    return data;
  }

  async onSubmit(contract: ContractDto) {
    this.loading = true;

    try {
      const res = await apartmentsService.updateContract(
        this.apartmentId,
        this.ownerId,
        contract,
      );
      this.contract = res.data;
      this.close(this.contract);
      this.$notify.success(this.$t('success.contractUpdated').toString());
    } catch (err) {
      this.handleError(err);
    } finally {
      this.loading = false;
    }
  }

  async getContract() {
    this.loading = true;

    try {
      const res = await apartmentsService.getContract(this.apartmentId, this.ownerId);
      this.contract = res.data;
    } catch (err) {
      this.handleError(err);
    } finally {
      this.loading = false;
    }
  }

  mounted() {
    this.getContract();
  }
}
