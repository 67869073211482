























































import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import UserDto from '@/app/user/dto/user.dto';
import Avatar from '@/app/core/components/avatar.vue';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import apartmentsService from '../services/apartments.service';

@Component({
  components: {
    Avatar,
  },
})
export default class AparmentAddOwnerDialog extends HandlesErrorMixin {
  private loading = true;

  owners: UserDto[] = [];

  selectedOwner: UserDto | null = null;

  @Prop({ type: String, required: true })
  readonly apartmentId!: string;

  @Emit('close')
  close(confirm: boolean) {
    return confirm;
  }

  @Watch('apartmentId', { immediate: true })
  async getApartmentOwners() {
    this.loading = true;

    try {
      const res = await apartmentsService.getApartmentNoOwners(this.apartmentId);
      this.owners = res.data.items;
    } catch (err) {
      this.handleError(err);
    } finally {
      this.loading = false;
    }
  }

  @Emit('close')
  onAdd() {
    return this.selectedOwner;
  }
}
