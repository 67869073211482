

































































































































import { Getter } from 'vuex-class';
import { Component, Watch } from 'vue-property-decorator';
import AuthModule from '@/app/auth/store';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import ConfirmationDialog from '@/app/core/components/confirmation-dialog.vue';
import ApartmentDto from '../dto/apartment.dto';
import ApartmentInfo from '../components/apartment-info.vue';
import ApartmentICal from '../components/apartment-ical.vue';
import apartmentsService from '../services/apartments.service';
import ApartmentOwners from '../components/apartment-owners.vue';
import ApartmentContract from '../components/apartment-contract.vue';
import ApartmentPictures from '../components/apartment-pictures.vue';
import ApartmentReservations from '../components/apartment-reservations.vue';
import ApartmentAutoMessages from '../components/apartment-auto-messages.vue';

@Component({
  metaInfo(this: ViewApartment) {
    return { title: this.$t('pageTitle.apartmentDetails').toString() };
  },
  components: {
    ApartmentInfo,
    ApartmentICal,
    ApartmentOwners,
    ApartmentPictures,
    ApartmentContract,
    ApartmentAutoMessages,
    ApartmentReservations,
  },
})
export default class ViewApartment extends HandlesErrorMixin {
  private tab = 'info';
  private loading = false;
  private apartmentId: string | null = null;
  private apartment: ApartmentDto | null = null;

  @Getter
  private isHostAdmin!: typeof AuthModule.prototype.isHostAdmin;

  @Getter
  private isHost!: typeof AuthModule.prototype.isHost;

  @Getter
  private isOwner!: typeof AuthModule.prototype.isOwner;

  @Watch('tab')
  onTabChange() {
    if (this.$route.params.tab !== this.tab) {
      this.$router.push(`/apartments/details/${this.apartmentId}/${this.tab}`);
    }
  }

  updateApartment() {
    if (this.apartmentId) {
      this.$router.push({
        name: 'update-apartment',
        params: { id: this.apartmentId },
        query: { redirect: this.$route.fullPath },
      });
    }
  }

  async onDelete() {
    const title = this.$t('title.deleteApartment');
    const message = this.$t('message.confirmDeleteApartment', { name: this.apartment?.name });
    const confirm = await this.$dialog.open(ConfirmationDialog, { title, message });

    if (confirm) {
      try {
        await apartmentsService.deleteApartment(this.apartmentId as string);
        this.$router.push('/apartments');
        this.$notify.success(this.$t('success.apartmentDeleted').toString());
      } catch (err) {
        this.handleError(err);
      }
    }
  }

  async getApartment() {
    if (!this.apartmentId) return;

    this.loading = true;

    try {
      const response = await apartmentsService.getApartment(this.apartmentId);
      this.apartment = response.data;
    } catch (error) {
      if (this.isNotFoundError(error)) {
        this.$router.push({ name: '404' });
      } else {
        this.handleError(error);
      }
    } finally {
      this.loading = false;
    }
  }

  created() {
    this.apartmentId = this.$route.params.id;
    this.getApartment();

    const { tab } = this.$route.params;
    this.tab = tab;
  }
}
