import { render, staticRenderFns } from "./apartment-auto-messages.vue?vue&type=template&id=bebd2bca&scoped=true&"
import script from "./apartment-auto-messages.vue?vue&type=script&lang=ts&"
export * from "./apartment-auto-messages.vue?vue&type=script&lang=ts&"
import style0 from "./apartment-auto-messages.vue?vue&type=style&index=0&id=bebd2bca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bebd2bca",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VCol,VForm,VRow,VSpacer,VSubheader,VTextarea})
