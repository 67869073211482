

















































































import { State } from 'vuex-class';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import UserDto from '@/app/user/dto/user.dto';
import Avatar from '@/app/core/components/avatar.vue';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import ContractDto from '../dto/contract.dto';
import apartmentsService from '../services/apartments.service';

@Component({
  components: {
    Avatar,
  },
})
export default class ApartmentContract extends HandlesErrorMixin {
  @State((state) => state.user)
  private user!: UserDto;

  @Prop({ type: Boolean, required: true }) loading!: boolean;

  @Prop({ type: String, required: true }) apartmentId!: string;

  contract: ContractDto | null = null;

  @Emit('setLoading')
  setLoading(loading: boolean) {
    return loading;
  }

  @Watch('apartmentId', { immediate: true })
  async getContract() {
    this.setLoading(true);

    try {
      const res = await apartmentsService.getContract(this.apartmentId, this.user.id);
      this.contract = res.data;
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setLoading(false);
    }
  }
}
